import React from 'react';

import Chevron from '../images/chevron-right.svg';
import { Link } from 'gatsby';

function BreadCrumbs({ data }) {
    return (
        <ul className="flex flex-row">
            {data.map((item, i) => (
                <li
                    key={i}
                    className="flex items-center font-serif text-sm md:text-base lg:text-lg">
                    {item.anchor ? (
                        <>
                            <Link
                                to={item.anchor}
                                className="border-b border-dashed">
                                {item.value}
                            </Link>
                            <Chevron
                                width={24}
                                height={24}
                                className="mx-1 md:mx-2"
                            />
                        </>
                    ) : (
                        <>
                            <span className="inline-block text-gray-400 w-32 sm:w-auto text-ellipsis whitespace-nowrap overflow-hidden">
                                {item.value}
                            </span>
                            {i !== data.length - 1 && (
                                <Chevron
                                    width={24}
                                    height={24}
                                    className="mx-1 md:mx-2"
                                />
                            )}
                        </>
                    )}
                </li>
            ))}
        </ul>
    );
}

export default BreadCrumbs;
