import React from 'react';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import BreadCrumbs from '../components/Breadcrumbs';
import Button from '../components/Button';

import Layout from '../components/Layout';
import TextBlock from '../components/TextBlock';

const Academy = ({ pageContext: { node } }) => {
    return (
        <Layout>
            <Seo post={node} />
            <div className="p-8 md:p-20 bg-yellow-300">
                <div className="mx-auto">
                    <span className="block text-2xl md:text-3xl uppercase text-center mb-5 text-black">
                        {node.types?.list[0]?.name}
                    </span>
                    <h1 className="text-3xl md:text-5xl uppercase text-center mb-4 mb:mb-6">
                        {node.title}
                    </h1>
                    {node.subtitle && (
                        <p className="font-serif font-normal text-center text-base lg:text-lg xl:text-xl text-gray-400">
                            {node.subtitle?.text}
                        </p>
                    )}
                </div>
            </div>

            <main className="w-full max-w-screen-xl mx-auto px-10 py-14 lg:grid lg:grid-cols-3 lg:gap-20 sticky">
                <div className="mb-10 lg:mb-0 lg:col-span-3">
                    <BreadCrumbs
                        data={[
                            { value: 'Home', anchor: '/' },
                            { value: 'Academy', anchor: '/academy' },
                            { value: node.title },
                        ]}
                    />
                </div>
                <div className="lg:col-span-2">
                    <div
                        className="textblock"
                        dangerouslySetInnerHTML={{ __html: node.content }}
                    />
                </div>

                <div className="lg:grid-cols-6 border-t lg:border-t-0 lg:border-l border-gray-200 pt-14 lg:pt-0 lg:pl-14 mt-14 lg:mt-0">
                    {!!node.academyDetails?.duration ||
                    !!node.academyDetails?.time ||
                    !!node.academyDetails?.venue ||
                    !!node.academyDetails?.price ? (
                        <div className="border shadow-md mb-10">
                            <div className="bg-gray-600 px-5 py-3 border-b">
                                <span className="text-2xl text-white">
                                    Academy details
                                </span>
                            </div>
                            <div className="p-5">
                                <ul>
                                    {!!node.academyDetails?.duration && (
                                        <li className="text-base font-serif text-gray-400 mb-2">
                                            <strong className="text-black">
                                                Duration:{' '}
                                            </strong>
                                            {node.academyDetails?.duration}
                                        </li>
                                    )}
                                    {!!node.academyDetails?.time && (
                                        <li className="text-base font-serif text-gray-400 mb-2">
                                            <strong className="text-black">
                                                Time:{' '}
                                            </strong>
                                            {node.academyDetails?.time}
                                        </li>
                                    )}
                                    {!!node.academyDetails?.venue && (
                                        <li className="text-base font-serif text-gray-400 mb-2">
                                            <strong className="text-black">
                                                Venue:{' '}
                                            </strong>
                                            {node.academyDetails?.venue}
                                        </li>
                                    )}
                                    {!!node.academyDetails?.price && (
                                        <li className="text-base font-serif text-gray-400 mb-2">
                                            <strong className="text-black">
                                                Price:{' '}
                                            </strong>
                                            &pound;
                                            {node.academyDetails?.price}
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    ) : null}
                    {node.bookPlaces?.events?.length > 0 && (
                        <div className="border shadow-md mb-10">
                            <div className="bg-gray-600 px-5 py-3 border-b">
                                <span className="text-2xl text-white">
                                    Book your place(s)
                                </span>
                            </div>
                            <div className=" divide-y divide-dotted divide-gray-200">
                                {node.bookPlaces.events.map((item, i) => (
                                    <div key={i}>
                                        <ul className="p-5">
                                            <li className="text-base font-serif">
                                                <p className="text-black">
                                                    {item.title}
                                                </p>
                                                <p className="text-gray-400">
                                                    {item.date}
                                                </p>

                                                <Button
                                                    text="Book a place"
                                                    border
                                                    className="mt-5"
                                                    link={item.url}
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {node.moreInformation && (
                        <div className="border shadow-md sticky top-24">
                            <div className="bg-gray-600 px-5 py-3 border-b">
                                <span className="text-2xl text-white">
                                    {node.moreInformation.moreTitle}
                                </span>
                            </div>
                            <div className=" divide-y divide-dotted divide-gray-200">
                                <div
                                    className="pt-5 pb-2 px-5 textblock"
                                    dangerouslySetInnerHTML={{
                                        __html: node.moreInformation.moreText,
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </main>
            {node.host?.title && node.host?.text && (
                <TextBlock
                    title={node.host?.title}
                    text={node.host?.text}
                    imagePosition="left"
                    image={
                        node.host?.image?.localFile?.childImageSharp?.resize
                            ?.src
                    }
                    backgroundColour="bg-yellow-300"
                    larger
                />
            )}
        </Layout>
    );
};

export default Academy;
